import Page from "../src/components/common/Page";
import Error_404 from "../src/components/Error/404";

export default function Error_404_Page() {
  return (
    <Page
      isPublic
      hideMenu
      hideWidget
      className="error-page"
      title="페이지를 찾지 못했다옹 | 소팬즈"
    >
      <Error_404 />
    </Page>
  );
}
