import { css } from "@emotion/react";

export default css`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  gap: 60px;
  background-color: white;
  .errorImg{
    width: 90%;
    max-width: 780px;
    align-items: center;
    svg{
      width: 100%;
      height: fit-content;
    }
  }
  .icon {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .text-404 {
      /*  */
    }

    .cat {
      display: flex;
      width: 80%;
      justify-content: center;
      svg{
        position: relative;
    margin-top: -18%;
    transform: translateX(70%);
    width: 50%;
    max-width: 104px;
    transition: 0.2s;
      }
    }
  }

  .text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: -48px;
    font-size: 1em;
    font-weight: 800;
    word-break: keep-all;
    text-align: center;
  }

  .button {
    max-width: 400px;
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #target-animation {
    transform-origin: 50% 50%;
    animation-duration: 5s;
    animation-name: r360;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @media screen and (max-width: 800px) {
      padding: 10%;
      box-sizing: border-box;
      .text{
        font-size: 0.8em;
      }
      .icon{
        .cat {
        svg{        
          width: 40%;
          margin-top: -30%;
          transform: translateX(60%);
          margin-bottom: 3%;
        }
      }
      }
      
  }
  @keyframes r360 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
