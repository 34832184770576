import Router from "next/router";
import css from "../.././../components/common/Layouts/Client/client.css";
import ErrorCss from "./404.css";
import Icon from "../../../assets/icons/404.icon";
import CatIcon from "../../../assets/icons/404Cat.icon";
import ButtonComponent from "../../../components/common/Button/Button.Icon";
import { PATH_PAGE } from "../../../paths";
import Head from "next/head";

import Page from "../../common/Page";

export default function Error_404() {
  const handleClick = () => {
    Router.push(PATH_PAGE.root.url);
  };

  return (
    <div className="wrap" css={ErrorCss}>
      <Head>
        <meta name="robots" content="noindex,nofollow" />
      </Head>
      <div className="errorImg">
        <div className="icon">
          <div className="text-404">
            <Icon />
          </div>
          <div className="cat">
            <CatIcon />
          </div>
        </div>
        <div className="text">
          <h1>Page is not found</h1>
          <h1>페이지를 찾지 못했다옹</h1>
        </div>
        <div className="button">
          <ButtonComponent
            onClick={handleClick}
            className="black-btn"
            icon="메인페이지로 모셔드리겠다옹 →"
          />
        </div>
      </div>
    </div>
  );
}
